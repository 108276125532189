











import SygniLoader from '@/components/layout/SygniLoader.vue';
import Vue from 'vue'
import Component from 'vue-class-component'
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import ContextHelper from '@/modules/shared/utils/ContextHelper';
import { mapState } from 'vuex';
import { Package } from '../models/Package';
import { UserContext, Contexts } from '../models/User';
import PlanCard from './PlanCard.vue';

@Component({
  components: { SygniLoader, GenproxPageHeading, PlanCard },
  computed: {
    ...mapState('genprox', {
      packages: (state: any) => state.packages,
      activeUserContext: (state: any) => state.activeUserData.context
    })
  }
})
export default class Plans extends Vue {
  packages!: Array<Package>;
  activeUserContext!: UserContext;

  chooseProduct(product: Package): void {
    if (product?.type !== 'on-demand') {
      if  (this.canAcceptPackage(product.requiredContext) ){
        this.$router.push({name: 'accept-package-by-id', params:{ id: product.id}});
      } else {
        this.$router.push({name: 'select-fund', query: {id: product.id}});
      }
    }
  }

  canAcceptPackage(requiredContext: Contexts){
    const canAcceptByFund = requiredContext === 'fund' && ( ContextHelper.isActiveContextFund || ContextHelper.isActiveContextFundManager);
    const canAcceptByCompany = requiredContext === 'company' && ( ContextHelper.isActiveContextCompany || ContextHelper.isActiveContextCompany);
    return canAcceptByCompany || canAcceptByFund || requiredContext === null;
  }
}
