var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "plans"
  }, [_c('GenproxPageHeading', {
    attrs: {
      "title": "Plans",
      "description": "Explore and select the perfect pricing plan tailored to your needs."
    }
  }), _c('div', {
    staticClass: "plans__container"
  }, _vm._l(_vm.packages, function (pack) {
    return _c('div', {
      key: pack.id,
      staticClass: "plans__box"
    }, [_c('PlanCard', {
      attrs: {
        "pack": pack
      },
      on: {
        "choosePackage": function choosePackage($event) {
          return _vm.chooseProduct($event);
        }
      }
    })], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }